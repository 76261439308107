<template>
    <div class="teacher-page">
        <div class="container pl-5">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb pl-0">
                    <li class="breadcrumb-item"><a href="#">{{ $t('breadcrumb.main-page') }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t('breadcrumb.teacher') }}</li>
                </ol>
            </nav>
            <div class="page-title text-center  text-lg-left mb-5">
                {{ $t('teacher.title') }}
            </div>
        </div>

        <section class="coursers">
            <div class="container">
                <div class="row">
                    <div class="col-lg-9 mx-auto px-2">
                        <div class="card shadow-none">
                            <div>
                                <div class="card-image">
                                    <img src="/images/teacher-1.jpg" alt="" class="w-100 ">
                                </div>
                                <div class="text-danger text-center mt-2">
                                    {{ $t('teacher.status') }}
                                </div>
                            </div>

                            <div class="card-content w-100">
                                <div class="d-flex justify-content-between flex-column">
                                    <div class="mx-xl-0 mx-auto">

                                        <div class="card-content-title">
                                            {{ $t('teacher.name') }}
                                        </div>

                                        <div class="card-content-subtitle">
                                            {{ $t('teacher.course') }}
                                        </div>
                                        <ul class="list-group">
                                            <li class="list-group-item">
                                                <span class="icon">
                                                    <svg width="10" height="9" viewBox="0 0 10 9" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M5 3.39844C5.93845 3.39844 6.69922 2.63767 6.69922 1.69922C6.69922 0.760766 5.93845 0 5 0C4.06155 0 3.30078 0.760766 3.30078 1.69922C3.30078 2.63767 4.06155 3.39844 5 3.39844Z"
                                                            fill="#666666" />
                                                        <path
                                                            d="M8.4375 3.39845C9.03077 3.39845 9.51172 2.9175 9.51172 2.32422C9.51172 1.73095 9.03077 1.25 8.4375 1.25C7.84423 1.25 7.36328 1.73095 7.36328 2.32422C7.36328 2.9175 7.84423 3.39845 8.4375 3.39845Z"
                                                            fill="#666666" />
                                                        <path
                                                            d="M1.5625 3.39845C2.15577 3.39845 2.63672 2.9175 2.63672 2.32422C2.63672 1.73095 2.15577 1.25 1.5625 1.25C0.969225 1.25 0.488281 1.73095 0.488281 2.32422C0.488281 2.9175 0.969225 3.39845 1.5625 3.39845Z"
                                                            fill="#666666" />
                                                        <path
                                                            d="M2.6209 4.36314C2.19805 4.0167 1.8151 4.06256 1.32617 4.06256C0.594922 4.06256 0 4.65396 0 5.38072V7.51372C0 7.82934 0.257617 8.08598 0.574414 8.08598C1.94211 8.08598 1.77734 8.11073 1.77734 8.027C1.77734 6.51556 1.59832 5.40714 2.6209 4.36314Z"
                                                            fill="#666666" />
                                                        <path
                                                            d="M5.46485 4.06992C4.61087 3.99869 3.86858 4.07074 3.22833 4.59922C2.1569 5.45743 2.36309 6.61296 2.36309 8.02657C2.36309 8.40058 2.66739 8.71056 3.04708 8.71056C7.16977 8.71056 7.33386 8.84355 7.57833 8.30216C7.6585 8.11907 7.63653 8.17726 7.63653 6.42579C7.63653 5.03465 6.43198 4.06992 5.46485 4.06992Z"
                                                            fill="#666666" />
                                                        <path
                                                            d="M8.67363 4.06251C8.18203 4.06251 7.80119 4.01712 7.37891 4.36309C8.39385 5.39934 8.22246 6.43213 8.22246 8.02695C8.22246 8.1112 8.08568 8.08593 9.40488 8.08593C9.73301 8.08593 9.9998 7.82011 9.9998 7.49335V5.38067C9.9998 4.65391 9.40488 4.06251 8.67363 4.06251Z"
                                                            fill="#666666" />
                                                    </svg>

                                                </span>
                                                <span>
                                                    {{ $t('teacher.students') }}
                                                </span>
                                            </li>
                                            <li class="list-group-item">
                                                <span class="icon">
                                                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M5.47525 7.80283H7.66926V0H0V10H5.47525V7.80283ZM3.83463 6.95963H1.75129V6.37369H3.83463V6.95963ZM4.45963 7.7474V8.33334H3.20963V7.7474H4.45963ZM5.91797 5.70963H1.75129V5.12369H5.91797V5.70963ZM5.91797 4.45963H1.75129V3.87369H5.91797V4.45963ZM5.91797 3.00131H1.75129V2.41537H2.97906C2.93893 2.31232 2.91666 2.20041 2.91666 2.08334C2.91666 1.57717 3.32846 1.16537 3.83463 1.16537C4.3408 1.16537 4.7526 1.57717 4.7526 2.08334C4.7526 2.20041 4.73033 2.31232 4.6902 2.41537H5.91797V3.00131Z"
                                                            fill="#666666" />
                                                    </svg>
                                                </span>
                                                <span>
                                                   {{ $t('teacher.lessons') }}
                                                </span>
                                            </li>
                                            <li class="list-group-item">
                                                <span class="icon">
                                                    <svg width="10" height="9" viewBox="0 0 10 9" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M9.58994 3.23898C9.55916 3.14413 9.47728 3.07501 9.3787 3.06068L6.60933 2.65787L5.37079 0.14602C5.32672 0.0566021 5.23573 0 5.13612 0C5.03649 0 4.94552 0.0566021 4.90142 0.14602L3.66284 2.65787L0.893514 3.06068C0.794951 3.07501 0.713033 3.14413 0.682255 3.23896C0.65146 3.33381 0.677143 3.43792 0.748488 3.50751L2.75234 5.46272L2.27936 8.22354C2.26251 8.32183 2.30288 8.42113 2.38347 8.47976C2.42906 8.51291 2.48307 8.52978 2.53733 8.52978C2.57899 8.52978 2.6208 8.51984 2.65908 8.49969L5.1361 7.19618L7.61301 8.49967C7.70121 8.54607 7.80804 8.53835 7.88864 8.47974C7.96923 8.42113 8.00962 8.3218 7.99278 8.22351L7.51965 5.46272L9.52373 3.50749C9.59505 3.43792 9.62075 3.33381 9.58994 3.23898Z"
                                                            fill="#666666" />
                                                    </svg>
                                                </span>
                                                <span>
                                                    {{ $t('teacher.rating') }}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                <div class="card-content-text">
                                    <p>{{ $t('teacher.desc') }}</p>
                                </div>
                                <div class="card-footer">
                                    <button class="btn btn-danger mt-0">
                                        {{ $t('teacher.send-message-button') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="container teacher-page">
            <div class="row">
                <div class="col-lg-9 mx-auto">
                    <section class="about">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 px-0">
                                    <div class="title_wline">
                                        <span>
                                            {{ $t('teacher.video') }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-12 px-0">
                                    <div class="video mt-lg-5" style="background-image: url('/images/video-bg.jpg');"
                                        data-fancybox data-width="640" data-height="360" href="video.mp4">
                                        <div class="icon-play">
                                            <svg width="24" height="27" viewBox="0 0 24 27" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23.5637 13.746L0.981902 26.7836L0.981903 0.708332L23.5637 13.746Z"
                                                    fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-9 mx-auto">
                    <div class="title_wline">
                        <span>
                            {{ $t('teacher.reviews') }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row pb-5 mb-4">
                <div class="col-lg-8 mx-auto">
                    <div class="card card-review mb-4">
                        <div class="card-image">
                            <img src="/images/r-teacher-1.jpg" alt="" class="img-fluid">
                        </div>
                        <div class="card-body ">
                            <div class="pl-0 pl-md-4 d-flex align-item-center flex-column flex-md-row">
                                <div class="teacher-rate d-flex ">
                                    <span><svg width="23" height="21" viewBox="0 0 23 21" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.5 0L14.0819 7.9463H22.4371L15.6776 12.8574L18.2595 20.8037L11.5 15.8926L4.74047 20.8037L7.32238 12.8574L0.56285 7.9463H8.91809L11.5 0Z"
                                                fill="#F5C980" />
                                        </svg>
                                    </span>
                                    <span><svg width="23" height="21" viewBox="0 0 23 21" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.5 0L14.0819 7.9463H22.4371L15.6776 12.8574L18.2595 20.8037L11.5 15.8926L4.74047 20.8037L7.32238 12.8574L0.56285 7.9463H8.91809L11.5 0Z"
                                                fill="#F5C980" />
                                        </svg>
                                    </span>
                                    <span><svg width="23" height="21" viewBox="0 0 23 21" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.5 0L14.0819 7.9463H22.4371L15.6776 12.8574L18.2595 20.8037L11.5 15.8926L4.74047 20.8037L7.32238 12.8574L0.56285 7.9463H8.91809L11.5 0Z"
                                                fill="#F5C980" />
                                        </svg>
                                    </span>
                                    <span><svg width="23" height="21" viewBox="0 0 23 21" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.5 0L14.0819 7.9463H22.4371L15.6776 12.8574L18.2595 20.8037L11.5 15.8926L4.74047 20.8037L7.32238 12.8574L0.56285 7.9463H8.91809L11.5 0Z"
                                                fill="#F5C980" />
                                        </svg>
                                    </span>
                                    <span><svg width="23" height="21" viewBox="0 0 23 21" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.5 0L14.0819 7.9463H22.4371L15.6776 12.8574L18.2595 20.8037L11.5 15.8926L4.74047 20.8037L7.32238 12.8574L0.56285 7.9463H8.91809L11.5 0Z"
                                                fill="#F5C980" />
                                        </svg>
                                    </span>
                                    <span class="rate-number">
                                        <span>
                                            {{ $t('teacher.mark-1') }}
                                        </span>
                                    </span>
                                </div>
                                <div class="teacher-name ">{{ $t('teacher.reviewer-1') }}</div>
                            </div>
                            <div class="content">
                                {{ $t('teacher.review-1') }}
                            </div>
                        </div>
                    </div>

                    <div class="card card-review mb-4">
                        <div class="card-image">
                            <img src="/images/r-teacher-2.jpg" alt="" class="img-fluid">
                        </div>
                        <div class="card-body ">
                            <div class="pl-0 pl-md-4 d-flex align-item-center flex-column flex-md-row">
                                <div class="teacher-rate d-flex">
                                    <span><svg width="23" height="21" viewBox="0 0 23 21" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.5 0L14.0819 7.9463H22.4371L15.6776 12.8574L18.2595 20.8037L11.5 15.8926L4.74047 20.8037L7.32238 12.8574L0.56285 7.9463H8.91809L11.5 0Z"
                                                fill="#F5C980" />
                                        </svg>
                                    </span>
                                    <span><svg width="23" height="21" viewBox="0 0 23 21" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.5 0L14.0819 7.9463H22.4371L15.6776 12.8574L18.2595 20.8037L11.5 15.8926L4.74047 20.8037L7.32238 12.8574L0.56285 7.9463H8.91809L11.5 0Z"
                                                fill="#F5C980" />
                                        </svg>
                                    </span>
                                    <span><svg width="23" height="21" viewBox="0 0 23 21" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.5 0L14.0819 7.9463H22.4371L15.6776 12.8574L18.2595 20.8037L11.5 15.8926L4.74047 20.8037L7.32238 12.8574L0.56285 7.9463H8.91809L11.5 0Z"
                                                fill="#F5C980" />
                                        </svg>
                                    </span>
                                    <span><svg width="23" height="21" viewBox="0 0 23 21" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.5 0L14.0819 7.9463H22.4371L15.6776 12.8574L18.2595 20.8037L11.5 15.8926L4.74047 20.8037L7.32238 12.8574L0.56285 7.9463H8.91809L11.5 0Z"
                                                fill="#F5C980" />
                                        </svg>
                                    </span>
                                    <span><svg width="23" height="21" viewBox="0 0 23 21" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.5 0L14.0819 7.9463H22.4371L15.6776 12.8574L18.2595 20.8037L11.5 15.8926L4.74047 20.8037L7.32238 12.8574L0.56285 7.9463H8.91809L11.5 0Z"
                                                fill="#F5C980" />
                                        </svg>
                                    </span>
                                    <span class="rate-number">
                                        <span>
                                            {{ $t('teacher.mark-2') }}
                                        </span>
                                    </span>
                                </div>
                                <div class="teacher-name">{{ $t('teacher.reviewer-2') }}</div>
                            </div>
                            <div class="content">
                                {{ $t('teacher.review-2') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn btn-primary mt-3 btn-lg">{{ $t('teacher.more-button') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            teachers: [
                {
                'id': "1",
                'name': this.$t('main-page.teacher-1-name'),
                'course': this.$t('main-page.teacher-1-course'),
                'students': this.$t('main-page.teacher-1-students'),
                'lessons': this.$t('main-page.teacher-1-lessons'),
                'rating': this.$t('main-page.teacher-1-rating'),
                'desc': this.$t('main-page.teacher-1-desc'),
                'feedbacks': this.$t('main-page.teacher-1-feedbacks'),
                'img': this.$t('main-page.teacher-1-img'),
                },
                {
                'id': "2",
                'name': this.$t('main-page.teacher-2-name'),
                'course': this.$t('main-page.teacher-2-course'),
                'students': this.$t('main-page.teacher-2-students'),
                'lessons': this.$t('main-page.teacher-2-lessons'),
                'rating': this.$t('main-page.teacher-2-rating'),
                'desc': this.$t('main-page.teacher-2-desc'),
                'feedbacks': this.$t('main-page.teacher-2-feedbacks'),
                'img': this.$t('main-page.teacher-2-img'),
                },
                {
                'id': "3",
                'name': this.$t('main-page.teacher-3-name'),
                'course': this.$t('main-page.teacher-3-course'),
                'students': this.$t('main-page.teacher-3-students'),
                'lessons': this.$t('main-page.teacher-3-lessons'),
                'rating': this.$t('main-page.teacher-3-rating'),
                'desc': this.$t('main-page.teacher-3-desc'),
                'feedbacks': this.$t('main-page.teacher-3-feedbacks'),
                'img': this.$t('main-page.teacher-3-img'),
                },
                {
                'id': "4",
                'name': this.$t('main-page.teacher-4-name'),
                'course': this.$t('main-page.teacher-4-course'),
                'students': this.$t('main-page.teacher-4-students'),
                'lessons': this.$t('main-page.teacher-4-lessons'),
                'rating': this.$t('main-page.teacher-4-rating'),
                'desc': this.$t('main-page.teacher-4-desc'),
                'feedbacks': this.$t('main-page.teacher-4-feedbacks'),
                'img': this.$t('main-page.teacher-4-img'),
                },
            ],
        }
    }
}
</script>
<style >
/* @media screen and (max-width: 991px) {
    .search-page .coursers .form-row {
        border: 1px solid #e0e0e0;
        padding: 18px 9px;
    }
}
@media (max-width: 574px) {
    .coursers {
        padding: 0 15px;
    }
} */
</style>